import styled from 'styled-components'

export const NavContainer = styled.div`
  background-color: ${props => props.color ? props.color : "black"};
  width: 95%;
  @media (min-width: 510px) {
    width: 500px;
  }
  @media (min-width: 800px) {
    width: 98%;
  }
  @media (min-width: 1000px) {
    width: 940px;
  }
  @media (min-width: 1200px) {
    width: 1150px;
  }
  @media (min-width: 1300px) {
    width: 1250px;
  }
`

export const Nav = styled.div`
  display: flex;
`

export const NavRight = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
`

export const NavLeft = styled.div`
  width: 70%;
`

export const Title = styled.h1`
  margin-top: 8px;
  font-size: 2em;
  color: #707371;
  font-weight: bolder;
  @media (min-width: 1000px) {
    font-size: 3em;
  }
`

export const Link = styled.h3`
  display: none;
  text-decoration: none;
  padding: 15px;
  padding-bottom: 0px;
  height: 29px;
  text-transform: uppercase;
  color: #707371;
  &:hover {
   border-bottom: 3px solid #2B4570;
  }
  @media (min-width: 950px) {
    display: block;
  }
`

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  left: -120rem;
  transition: left .75s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.show {
    left: 0;
  }
  & > a {
    color: white;
  }
`

export const Ul = styled.ul`
  padding: 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Li = styled.li`
  list-style: none;
  width: 100%;
  text-align: center;
  height: 30px;
  margin-bottom: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  background: gray;
  color: white;
`

