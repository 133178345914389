import React, { useState } from 'react'
import Hamburger from './Hamburger'

import { 
  NavContainer,
  Nav,
  NavRight,
  NavLeft,
  Title,
  Ul,
  Li,
  Link,
  Overlay
 } from './style'

const NavBar = ({navigate}) => {
  const [ isOpen, setIsOpen ] = useState(false)
  window.onresize = () => (window.innerWidth > 1000 && isOpen) && setIsOpen(false)
  return (
    <NavContainer color={'white'}>
      <Nav>
        <NavLeft>
          <Title>Lyn's<br /> Legal Briefing</Title>
        </NavLeft>
        <NavRight onClick={navigate}>
            <Link>Home</Link>
            <Link>Services</Link>
            <Link>Contact</Link>
            <Hamburger setIsOpen={setIsOpen} isOpen={isOpen}/>
        </NavRight>
      </Nav>
      <Overlay className={isOpen ? "show" : "hide"}>
        <Ul onClick={navigate}>
          <Li onClick={() => setIsOpen(!isOpen)}>Home</Li>
          <Li onClick={() => setIsOpen(!isOpen)}>Services</Li>
          <Li onClick={() => setIsOpen(!isOpen)}>Contact</Li>
        </Ul>
      </Overlay>
    </NavContainer> 
  )
}

export default NavBar
