import styled from 'styled-components'
import { MailSend } from '@styled-icons/boxicons-regular/MailSend'
import { Phone } from '@styled-icons/boxicons-solid/Phone'

export const Mail = styled(MailSend)`
  width: 35px;  
  margin-right: 5px;
`

export const Number = styled(Phone)`
  width: 35px;  
  margin: 5px;
`

export const Body = styled.div`
  width: 100%;
  margin: 0px;
  font-family: 'Montserrat', regular;
`

export const NavBarContainer = styled.div`
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
`

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  position: relative;
  top: 123px;
  @media (min-width: 1000px) {
    top: 156px;
  }
`

export const Section = styled.section`
  width: 95%;
  height: 419px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid black;
  @media(min-width: 510px) {
    width: 500px;
  }
  @media (min-width: 800px) {
    width: 98%;
  }
  @media (min-width: 1000px) {
    width: 940px;
  }
  @media (min-width: 1200px) {
    width: 1150px;
  }
  @media (min-width: 1300px) {
    width: 1250px;
  }
  &:nth-child(1) {
    @media (min-width: 800px) {
      height: 300px;
      flex-direction: row;
    }
  }
  &:nth-child(2) {
    flex-direction: column;
    height: 137em;
    @media(max-width: 4000px) {
      height: 41em;
    } 
    @media(max-width: 1300px) {
      height: 63em;
    }
    @media(max-width: 1200px) {
      height: 64em;
    }
    @media(min-width: 800px) {
      margin-top: 80px;
    }
    @media(max-width: 800px) {
      height: 110em;
    }
    @media(max-width: 414px) {
      height: 120em;
    }
    @media(max-width: 375px) {
      height: 125em;
    }
    @media(max-width: 360px) {
      height: 127em;
    }
    @media (max-width: 320px) {
      height: 137em;
    }
  }
  &:nth-child(3) {
    height: 254px;
    border-bottom: 0px;
  }
`

export const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
`

export const SectionBody = styled.div`
  display: flex;
  width: 100%;
  height: 375px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1300px) {
    flex-direction: row;
    height: 567px;
  }
`

export const SectionDiv = styled.div`
  padding: 3px;
  @media (min-width: 800px) {
    display: flex;
    margin-bottom: 30px;
  }
  @media (min-width: 1300px) {
    display: block;
  }
  
`

export const SectionH1 = styled.h1`
  font-size: 2em;
  @media (min-width: 800px) {
    display: none;
  }
`

export const Type = styled.h1`
  font-size: 2em;
  display: none;
  @media (min-width: 800px) {
    display: block
  }
`

export const SectionP = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  text-align: center;
  margin-top: 0px;
  @media (min-width: 800px) {
    font-size: 1.1em;
    line-height: 1.7;
  }
`

export const Article = styled.article`
  width: 100%;
  &:nth-child(1) {
    display: flex;
    align-items: center;
    @media (min-width: 800px) {
      display: block;
    }
  }
  &:nth-child(2) {
    & p {
      text-align: center;
    }
  }
`

export const Scale = styled.img`
  width: 100%;
  height: 270px;
`

export const Img1 = styled.img`
  width: 100%;
  height: 270px;
  @media (min-width: 800px) {
    width: 50%;
    order: 2;
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
`

export const Img2 = styled.img`
  width: 100%;
  height: 270px;
  @media (min-width: 800px) {
    width: 50%;
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
`

export const Img3 = styled.img`
  width: 100%;
  height: 270px;
  @media (min-width: 800px) {
    width: 50%;
    order: 2;
  }
  @media (min-width: 1300px) {
   width: 100%;
  }
`

export const P = styled.p`
  line-height: 1.8;
  font-size: 1.1em;
  margin-top: 0px;
  @media (min-width: 1200px) {
    font-size: 1.4em;
  }
`

export const H2 = styled.h2`
  display: none;
  text-align: center;
  @media (min-width: 800px) {
   display: block;
  }
  font-size: 2em;
`

export const Footer = styled.footer`
  width: 100%;
  height: 100px;
`

export const ContactDiv = styled.div`
`

export const LocationPic = styled.img`

`

export const Map = styled.div`

`

export const LinkMail = styled.a`
  text-decoration: none;
  color: black;
`