import React from 'react';
import NavBar from './NavBar'

import {
  Body,
  Main,
  Footer,
  Scale,
  P,
  H2,
  Section,
  SectionHeader,
  SectionH1,
  SectionBody,
  SectionDiv,
  SectionP,
  Article,
  ContactDiv,
  Mail,
  Number,
  LinkMail,
  Img1,
  Img2,
  Img3,
  NavBarContainer,
  Type
} from './style'

function App() {
  const navigate = (e) => {
    let section = e.target.textContent
    console.log(section, 'this is section')
    if(section === 'Home') {
      window.scroll({ top: 0, left: 0, behavior: 'smooth'})
    } else if(section === 'Services') {
      if(window.outerWidth >= 800) {
        window.scroll({ top: 320, left: 0, behavior: 'smooth'})
      } else {
        window.scroll({ top: 450, left: 0, behavior: 'smooth'})
      }
    } else if (section === 'Contact') {
      window.scroll({ top: 3050, left: 0, behavior: 'smooth'})
    }
   }
  return (
    <Body>
      <NavBarContainer>
        <NavBar navigate={navigate} />
      </NavBarContainer>
      <Main>
        <Section>
          <Article>
            <Scale src="https://i.imgur.com/b1amKjH.jpg" alt="lyns legal briefing" title="lyns legal briefing" />
          </Article>
          <Article>
            <H2>Brief Service</H2>
            <P>We Are a Briefing Service with over 15 Years of Legal Experience. We Are Knowledgeable of State and Local Filing Requirements.</P>
          </Article>
        </Section>
        <Section>
          <SectionHeader>
            <SectionH1>Services</SectionH1>
            <Type>Type's of Services</Type>
          </SectionHeader>
          <SectionBody>
            <SectionDiv>
              <Img1 className="img" src="https://i.imgur.com/B6BNjps.jpg" alt="lyns legal briefing" title="lyns legal briefing" />
              <SectionP>We specialize in the preparation of all types of Appellate documents. This includes creating Tables of Contents and/or Tables of Authorities as required. We will take your document from hard copy, disc or email and prepare it with the proper format of document sequencing and pagination. We will ensure that all documents are compliant with Court rules.</SectionP>
            </SectionDiv>
            <SectionDiv>
              <Img2 className="img" src="https://i.imgur.com/roLDbvf.jpg" alt="lyns legal briefing" title="lyns legal briefing" />
              <SectionP>We offer the preparation of legal documents. We understand that sometimes schedules get busy, and if you need more time to complete your briefing, we can draft stipulations for extensions of time. We are also aware that statements of word compliance and certificates of interested parties are needed and we can prepare those for you, as well.</SectionP>
            </SectionDiv>
            <SectionDiv>
              <Img3 className="img" src="https://i.imgur.com/IAmYKN6.jpg" alt="lyns legal briefing" title="lyns legal briefing" />
              <SectionP>We offer bookmarking services. We understand that bookmarking can be a tedious task let us handle that for you. As we know that all documents filed with the Court of Appeal have specific requirements and with our extensive knowledge of certain software programs, we can assist in this regard.</SectionP>
            </SectionDiv>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>
            <SectionH1>Contact</SectionH1>
          </SectionHeader>
            <ContactDiv>
              <SectionP>< Mail /><LinkMail href="mailto:lynslegalbriefing@gmail.com">lynslegalbriefing@gmail.com</LinkMail></SectionP>
              <SectionP><Number />818-617-3604</SectionP>
            </ContactDiv>
        </Section>
      </Main>
      {/* <Footer>
      </Footer> */}
    </Body>
  );
}

export default App;

